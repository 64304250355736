<template>
<el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div class="well">
        <el-form
          ref="ruleForm"
          :model="data"
          status-icon
          :rules="rules"
          label-width="180px"
          class=" login-form"
          :data="formData"      
          :label-position="labelPosition"
        >
          <el-row :gutter="20">
             <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="6"
              :xl="6"
            >
              <el-form-item
                label="Name"
                prop="filter_name"
              >
                <el-input
                  v-model="data.filter_name"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="6"
              :xl="6"
            >
              <el-form-item
                label="Emaail"
                prop="filter_email"
              >
                <el-input
                  v-model="data.filter_email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="6"
              :xl="6"
            >
              <el-form-item
                label="Phone"
                prop="filter_phone"
              >
                <el-input
                  v-model="data.filter_phone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24"
              :sm="24"
              :md="24"
              :lg="6"
              :xl="6"
            >
            <el-form-item
            label="&nbsp;"
              >
              <el-button
                  type="success"
                  icon="el-icon-search"
                  @click="doClickSearch()"
                >
                  Search
                </el-button>
                <el-button
                  @click="performLeads('ruleForm')"
                >
                   Reset 
                </el-button>
            </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-col>
</el-row>
<div class="panel">
  <el-row :gutter="20">
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="heading"> 
        <h1>{{title}}</h1>
      </div>
    </el-col>
    <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
      >
        <div v-if="showMessage">
          <el-alert
              title="{{message.message}}"
              type="{{message.messageType}}"
              effect="{{message.effect}}"
          />
        </div>
      </el-col>
    </el-row>
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="topsearch">
        <el-input
            v-model="search"
            size="small"
            placeholder="Type to search by name,message and source "
            @keyup="performSearch"
        >
          <template #append>
            <el-button icon="el-icon-search" @click="doClickSearch()"></el-button>
          </template>
        </el-input>
        <el-button
            type="primary"
            @click="performAddNew()"
        >
          {{addLabel}}
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-table
      ref="multipleTable"
      :data="rowsData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      :header-cell-class-name="tableCellClassName"
      @selection-change="handleSelectionChange"
      :show-header="true"
  >
    <el-table-column
        type="selection"
        width="55"
        style="padding: 0;"
    />
    <el-table-column
        v-for="column in columns"
        :key="column.key"
        :prop="column.key"
        :label="column.label"
        :sortable="column.sortable"
        :width="column.width"
    >
      <template #default="scope" v-if="column.is_image">       
        <el-image
            style="width: 60px; height: 60px; border-radius: 50%;text-align: center;margin-top:5px;"
            :src="scope.row.image"
        >
          <template #error>
            <div class="image-slot" style="margin-top: 39%;">
              <i class="el-icon-picture-outline"></i>
            </div>
          </template>
        </el-image>
      </template> 
      
    </el-table-column>
    <el-table-column
        align="center"
        width="100"
    >
      <template #header>
        ACTIONS
      </template>
      
      <template #default="scope">
        <el-popover
    placement="bottom"
    :width="125"
    trigger="click"
  >
        <el-button
            size="small"
            title="Edit Lead"
            type="primary" 
            @click="performEdit(scope.row.id)"
            style="margin-bottom: 5px;width:100px;"
        ><i class="fa fa-edit i-style-sidebar"></i> Edit Lead
        </el-button>
        <br/>
        <el-button
            size="small"
            type="primary"
            title="User Detail"      
            @click="performDetail(scope.row.customer_id)"
            style="margin-bottom: 5px;width:100px;"            
        ><i class="fa fa-user i-style-sidebar"></i> User Detail
        </el-button><br>
        <el-button
            size="small"
            type="danger"
            style="margin-bottom: 5px;width:100px;"  
            title="Delete Lead" 
            @click="performDelete(scope.$index, scope.row)"
        ><i class="fa fa-trash i-style-sidebar"></i> Delete Lead</el-button>
    <template #reference> <i class="fa fa-ellipsis-v i-style-sidebar"></i>
    </template>
  </el-popover>
      </template>
    </el-table-column>
  </el-table>
  </div>
  <div class="pagination">
    <el-pagination
        background
        layout=" sizes, prev, pager, next"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="totalPages"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import settings from "@/settings";

export default {
  name: "list-content",
  props: {
    columns: Array,
    rowsData: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: "List"
    },
    subTitle: {
      type: String,
      default: ""
    },
    addLabel: {
      type: String,
      default: 'Add New'
    },
    meta: {current_page: Number, from: Number, last_page: Number, links: Array, path: String, per_page: String, to: Number, total: Number},
    message: {message:String, messageType: String, effect: String},
    showMessage: {
      type: Boolean,
      default: false
    },
    newUri: String,
    editUri: String
  },
  data() {
    return {
      labelPosition:"top",
      formData:[],      
      data:{
          filter_name:null,
          filter_source:null,
          filter_email: null,
          filter_phone:null,
    },
      page: 1,
      pageSize: settings.pagination.size,
      pageSizes:[1,2,5,10,25,50],
      multipleSelection: [],
      totalPages: 0,
      search: '',
    }
  },
  mounted() {},
  computed: {},
  watch: {
    meta: function () {
      this.totalPages = this.meta.total;
    },
  },
  emits: ["handlePageChange", "handleSearchData", "handleDeleteData","handlepageSizeChange"],
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row && (rowIndex % 2 === 1)) {
        return 'table-row success-row';
      }else {
        return 'table-row';
      }
    },
    tableCellClassName() {
      return 'tableHeading';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$emit("handlePageChange", val);
    },
    handleSizeChange(val) {
      settings.pagination.size = val;
      this.pageSize=val;  
      this.$emit("handlepageSizeChange", val);
    },
    doClickSearch() {
      if(this.search === '' && this.data.filter_name === '' && this.data.filter_email === '' && this.data.filter_phone === '' && this.data.filter_source === '') {
        return;
      }   

      this.$emit("handleSearchData", {'name':this.search,'filter_name':this.data.filter_name, 'filter_email':this.data.filter_email,'filter_phone':this.data.filter_phone,'filter_source':this.data.filter_source});
    },
    performSearch(event) {       
      if (event.key === "Enter" || (event.key === "Backspace" && this.search === '') || (this.data.filter_name !== '' && this.data.filter_email !== '' && this.data.filter_phone === '' && this.data.filter_source !== '')) {
         this.$emit("handleSearchData", {'name':this.search,'filter_name':this.data.filter_name,'filter_email':this.data.filter_email,'filter_phone':this.data.filter_phone,'filter_source':this.data.filter_source});
       }
    },
    performAddNew() {
      this.$router.push(this.newUri);
    },
     performEdit(editid){
      this.$router.push(this.editUri+"/"+editid);
    },
    performDetail(detail){
      this.$router.push({ path: "/customerprofile/"+detail });
    },
    performLeads(formNameLead){      
      this.$refs[formNameLead].resetFields();
      this.$emit("handleSearchData", {'name':this.search});       
    },
    performDelete(indexNo = null, deleteRow = null) {
      let result = confirm("Are you sure to delete this?");
      if (indexNo >= 0 && result) {
        this.$emit("handleDeleteData", {'indexNo':indexNo, 'row': deleteRow});
      }
    }
  }
}
</script>

<style lang="scss">
.el-popover.el-popper {
  min-width: 75px !important;
}
.el-input--mini .el-input__inner {
  height: 40px;
}
 .panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #FFFFFF;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
</style>

<style lang="scss" scoped>
.topsearch .el-input {
  width: 500px;
}

.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
  float: right;
  display: inline-flex;
  padding: 15px 0px;
}

div .topsearch .el-input__inner {
  padding-right: 10px;
  height: 40px;
  width: 500px;
}

div .topsearch .el-input {
  padding-right: 10px;
}
.pagination {
  padding: 12px 5px;
  float: right;
}
.el-table :deep(.tableHeading) {
  background: #bebbc936 !important;
  text-transform: uppercase;
  font-size: .857rem;
  font-weight: 600;
  letter-spacing: .5px;
  color: #6e6b7b;
  font-family: Montserrat,Helvetica,Arial,serif;
  line-height: 1.45;
  height: 40px;
  padding: 0;
}
.el-table__row {
  height: 50px;
}
.el-table ::v-deep(.success-row) {
  background: #f0f9eb61;
}
.el-pagination :deep(button) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .number) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .active) {
  background-color: #f00 !important;
}
.el-table :deep(.table-row td) {
  padding: 0 !important;
}
</style>
